:root {
    --background: #282c34;
    --text: white;
    --hover: yellow;
    --active: orange;
}


html {
    background-color: var(--background);
    
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Mario-header {
    background-color: var(--background);
    display:  flex;
    flex-direction: row;
    font-size: 1.5em;
    /*font-size: calc(10px + 2vmin);*/
    color: var(--text);
    justify-content: space-between;
}

.Header-left {
    display: flex;   
}

.Header-right {
    display: block;
    align-items:end;
    margin-right:20px;
}

.Mario-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--text);
}

.Page {
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: var(--text);
    border-bottom: solid 1px white;
    padding-bottom: 20px;
}

.App-header {
    background-color: var(--background);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /*font-size: calc(10px + 2vmin);*/
    color: var(--text);
}

.App-link {
    color: #61dafb;
}


.Menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

a.Menu_link {
    font-size: 20px;
    /*font-size: calc(10px + 2vmin);*/
    color: var(--text);
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
}

    a.Menu_link:hover {
        text-decoration: underline;
        color: var(--hover);
    }







.active {
    color: var(--active) !important;    /* In order to be more specific */
}



@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

}

